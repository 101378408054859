import React from 'react';
import SearchBar from '../../components/search-bar/SearchBar';

const Discover = () => {
  return (
    <>
      <SearchBar />
    </>
  );
};

export default Discover;
